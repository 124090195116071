import React from "react"
import { graphql } from "gatsby"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderKontakt from "../components/header_kontakt"

const KontaktPage = ({ data }) => {
  const qry = data.allStrapiAdresseAnfahrt.edges[0].node

  return (
    <Layout>
      <SEO title="Kontakt Adresse/Anfahrt" />
      <HeaderKontakt />

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.1986007526802!2d7.697174215841754!3d47.913194574740714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47910f387438d679%3A0x573f9bf3169adbc8!2sMittweg%206%2C%2079189%20Bad%20Krozingen!5e0!3m2!1sde!2sde!4v1570753826218!5m2!1sde!2sde" width="100%" height="550" frameborder="0" allowfullscreen=""></iframe>
      <br /><br />
      <Reactmarkdown>{qry.content}</Reactmarkdown>
    </Layout>
  )
}

export default KontaktPage

export const query = graphql`
  query KontaktQuery {
    allStrapiAdresseAnfahrt {
      edges {
        node {
          content
        }
      }
    }
  }
`
