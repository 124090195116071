import { Link } from "gatsby"
import React from "react"

const HeaderKontakt = ({ siteTitle }) => (
  <div className="header-nav left">
    <ul>
      <li><Link to="/kontakt">Adresse/Anfahrt</Link></li>
      <li><Link to="/kontakt/datenschutz">Datenschutz</Link></li>
      <li><Link to="/kontakt/impressum">Impressum</Link></li>
    </ul>
  </div>
)

export default HeaderKontakt
